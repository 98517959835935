export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0];

export const dictionary = {
		"/[[lang=locale]]/about": [4,[2]],
		"/[[lang=locale]]/blog": [~5,[2]],
		"/[[lang=locale]]/blog/[slug]": [6,[2]],
		"/[[lang=locale]]/contact": [7,[2]],
		"/[[lang=locale]]/docs": [8,[2]],
		"/[[lang=locale]]/docs/[slug]": [9,[2]],
		"/[[lang=locale]]/duty-calc": [10,[2]],
		"/[[lang=locale]]/hs-codes": [11,[2]],
		"/[[lang=locale]]/services": [12,[2]],
		"/[[lang=locale]]/services/[service]": [13,[2]],
		"/[[lang=locale]]/software": [14,[2]],
		"/[[lang=locale]]": [3,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';